import{useCallback,useState}from'react';import{useTranslation}from'react-i18next';import{useApolloClient}from'react-apollo';import{sumUpReceipt,updateCustomerMutation}from'@myfox/common/src/queries';import{useRNView,useElectron}from'@myfox/common/src/lib';import{nameFormat,moneyFormat}from'@myfox/common/src/lib/textUtils';import{ConfirmModal,InfoModalContainer}from'../modals';import{curSymbol,subjectId}from'../../settings';import{useInitSumUp,useSavedSumUp}from'../SettingsSumUpDetail';import{FLAG_PAY_BPAYMENT,FLAG_PAY_GLOBAL_PAYMENT,FLAG_PAY_SONET,useHasFeatureFlag}from'../common';const getRandomInt=max=>Math.floor(Math.random()*Math.floor(max));let iterator=1;//FIXME: WTF: Vymyslet jak posílat unikátní ID - číslo účtenky?
let packet_id=getRandomInt(10000);// console.log(packet_id)
const communicateWithTerminal=(price,changeModalText,billNumber)=>{iterator+=1;return new Promise((resolve,reject)=>{const messageReceive=event=>{const message=JSON.parse(event.detail);switch(message.id){case'Card.success':packet_id=+message.data.packet_id+1;window.removeEventListener('App.communication',messageReceive);resolve({code:1,paymentInfo:message.data.paymentInfo});break;case'Card.error':packet_id=+message.data.packet_id+1;window.removeEventListener('App.communication',messageReceive);resolve(message.data.msg);break;case'Card.info':changeModalText(message.data.msg);break;default://nothing
}};window.addEventListener('App.communication',messageReceive);// console.log(packet_id)
window.postMessage(JSON.stringify({id:'Card.pay',data:{price,session_id:1000+iterator,packet_id,billNumber}}));});};function useElectronTerminal(){const hasBPayment=useHasFeatureFlag(FLAG_PAY_BPAYMENT);const hasGPayment=useHasFeatureFlag(FLAG_PAY_GLOBAL_PAYMENT);const hasSonet=useHasFeatureFlag(FLAG_PAY_SONET);const isElectron=useElectron();// TODO check if terminal is configured
return isElectron&&(hasBPayment||hasGPayment||hasSonet);}let paymentInProcess=false;export function usePayment(_ref){let{id,subject,shop,customer,openModal,closeModal,changeModalText}=_ref;const{t}=useTranslation();const client=useApolloClient();const isRNView=useRNView();const shouldUseSumup=useSavedSumUp((subject===null||subject===void 0?void 0:subject.id)||null);const[triggeredInitSumup,triggerInitSumup]=useState();useInitSumUp((subject===null||subject===void 0?void 0:subject.id)||null,triggeredInitSumup);const hasElectronTerminal=useElectronTerminal();const sumupPay=useCallback(async function(price){let overAmount=arguments.length>1&&arguments[1]!==undefined?arguments[1]:0;return new Promise((resolve,reject)=>{triggerInitSumup(true);let sumUpLoginTimeout=null;// Sumup is not logged
const makePayment=()=>{paymentInProcess=true;const paymentTitle=`myFox${subject&&subject.pureName?', '+subject.pureName:''}${customer?`, ${nameFormat(customer)}`:''}`;window.ReactNativeWebView.postMessage(JSON.stringify({id:'SumUp.payment',data:{ammount:price.toFixed(2).toString().replace(',','.'),billAmount:(price-overAmount).toFixed(2).toString().replace(',','.'),overAmount:overAmount.toFixed(2).toString().replace(',','.'),currency:subject.currency,title:paymentTitle,billNumber:id+'_'+Date.now()}}));};// listen for sumup login
const callback=async event=>{let message=JSON.parse(event.detail);switch(message.id){case'SumUp.login':triggerInitSumup(true);// Sumup is logged
// close waiting dialog && clear timeout
clearTimeout(sumUpLoginTimeout);closeModal();if(!paymentInProcess){makePayment();}break;case'SumUp.paymentOk':paymentInProcess=false;window.removeEventListener('App.communication',callback);window.ReactNativeWebView.postMessage(JSON.stringify({id:'App.log',data:JSON.stringify(message.data)}));setTimeout(async()=>{try{const restApiData=await client.query({query:sumUpReceipt,variables:{subjectId:(subject===null||subject===void 0?void 0:subject.id)||subjectId(),receiptId:message.data.transaction_code}});window.ReactNativeWebView.postMessage(JSON.stringify({id:'App.log',data:JSON.stringify(restApiData.data.sumUpReceipt.receipt)}));resolve({...message.data,...restApiData.data.sumUpReceipt.receipt});}catch(e){resolve(message.data);}},100);break;case'SumUp.paymentFalse':paymentInProcess=false;window.removeEventListener('App.communication',callback);changeModalText(t('Platba kartou se nezdařila!'));openModal(InfoModalContainer,{className:'simple'});setTimeout(()=>{closeModal();},4000);reject();break;default:// nothing
}};window.addEventListener('App.communication',callback);if(triggeredInitSumup){if(!paymentInProcess){makePayment();}}else{sumUpLoginTimeout=setTimeout(()=>{// show waiting dialog after delay
changeModalText(t('Čekání na přihlášení do SumUp.'));openModal(InfoModalContainer,{className:'simple info'});},500);}});},[changeModalText,client,closeModal,customer,id,openModal,subject,t,triggeredInitSumup]);const terminalPay=useCallback(async(price,billNumber)=>{return new Promise(async(resolve,reject)=>{changeModalText('Inicializace spojení');openModal(InfoModalContainer,{message:t('Zpracovávám platbu - řiďte se pokyny na terminálu')});await communicateWithTerminal(price,changeModalText,billNumber).then(terminalResponse=>{closeModal();if(terminalResponse.code!==1){changeModalText(terminalResponse.msg);openModal(InfoModalContainer,{message:t('Platba kartou se nezdařila')+'!'});setTimeout(()=>{closeModal();},4000);reject();}else{resolve(terminalResponse.paymentInfo);}});});},[changeModalText,closeModal,openModal,t]);const dialogPay=useCallback(async price=>{return new Promise(async(resolve,reject)=>{openModal(ConfirmModal,{onSubmit:async()=>{closeModal();resolve(null);},handleCancel:()=>{closeModal();reject();},className:'info',confirmButton:t('Hotovo'),cancelButton:t('Zrušit'),question:t('Proveďte platbu na terminálu. Částka je {{price}}',{price:moneyFormat(price.toFixed(2),curSymbol())})},'Platba kartou');});},[closeModal,openModal,t]);const cardPay=useCallback(async function(price,overAmount){let billNumber=arguments.length>2&&arguments[2]!==undefined?arguments[2]:'';if(isRNView&&shouldUseSumup){return await sumupPay(price,overAmount);}if(hasElectronTerminal){return await terminalPay(price,billNumber);}return await dialogPay(price);},[dialogPay,hasElectronTerminal,isRNView,shouldUseSumup,sumupPay,terminalPay]);const creditPay=useCallback(async(customer,amount)=>{return client.mutate({mutation:updateCustomerMutation,variables:{customerId:customer.id,credit:customer.credit-amount}});},[client]);return{cardPay,creditPay};}
import gql from'graphql-tag';export const userMyFoxFragment=gql`
	fragment userMyFox on UserMyFox {
		id
		name
		surname
		email
		phone
		address
		iCalId
		changePassReq
		locale
		__typename
		userPicture {
			id
			name
			url
			secret
		}
	}
`;export const addressFragment=gql`
	fragment address on Address {
		id
		city
		street
		zip
		longitude
		latitude
		__typename
	}
`;export const micrositeFragment=gql`
	fragment microsite on Microsite {
		id
		heading
		facebookId
		publish
		publishSnippet
		publishGroupSnippet
		endtext
		website
		template
		meta
		logo {
			id
			secret
		}
		frontImg {
			id
			secret
		}
		carousel {
			id
			secret
		}
	}
`;export const itemFragment=gql`
	fragment item on Item {
		id
		duration
		pauseAfter
		name
		price
		priceVat
		vat
		color
		note
		ord
		ean
		snippetDisabled
		stock
		stockEnabled
		type
		meta
		deleted
		picture {
			id
			secret
			name
		}
		categories {
			id
			name
			color
		}
		isBailActive
		bailAmount
	}
`;export const shopFragment=gql`
	fragment shop on Shop {
		id
		name
		phone
		email
		snippetMinutes
		displayPrices
		openOnHoliday
		timezone
		address {
			...address
		}
		meta
		subjectToShops {
			id
			subject {
				id
				sendEmail
				sendSms
				vatPayer
				name
				currency
				ico
				dic
				address {
					...address
				}
			}
		}
	}
	${addressFragment}
`;export const openingFragment=gql`
	fragment opening on Opening {
		id
		order
		day
		from
		to
		odd
		openingHead {
			id
		}
	}
`;export const openingHeadFragment=gql`
	fragment openingHead on OpeningHead {
		id
		validFrom
		employeeOwnOpening
		parity
		employee {
			id
		}
		shop {
			...shop
		}
		openings {
			...opening
		}
	}
	${shopFragment}
	${openingFragment}
`;export const noteFragment=gql`
	fragment note on Note {
		id
		text
		pin
		createdAt
		images {
			id
			name
			url
			secret
		}
		createdBy {
			id
			name
			surname
			employee {
				id
				color
				shops {
					id
					timezone
				}
			}
		}
	}
`;export const subjectFragment=gql`
	fragment subject on Subject {
		id
		name
		sendSms
		sendEmail
		ico
		vatPayer
		locale
		subjectExtIds {
			id
			extId
			code
			meta
		}
		subjectToShops {
			id
			shop {
				id
			}
			subject {
				id
			}
		}
	}
`;export const customersBailFragment=gql`
	fragment customersBail on CustomersBail {
		id
		amount
		dueTo
		paidAt
		refundedAt
		createdAt
		updatedAt
		expiredAt
		automaticCanceledAt
		archivedAt
		refundUntil
		state
		sumupCheckoutId
		transactionId
		transactionCode
		subjectToShop {
			id
			subject {
				id
			}
		}
	}
`;export const billFragment=gql`
	fragment bill on Bill {
		id
		billNumber
		name
		carts {
			id
			name
			count
			vat
			price
			priceVat
			createdAt
			createdBy {
				id
				name
				surname
				email
				phone
				address
			}
			updatedAt
			updatedBy {
				id
				name
				surname
				email
				phone
				address
			}
			item {
				id
				duration
				pauseAfter
				name
				price
				priceVat
				vat
				color
				note
				meta
				type
				snippetDisabled
				stock
				stockEnabled
				categories {
					id
				}
			}
		}
		customers {
			id
			name
			surname
			email
			phone
			address
			locale
			ean
			credit
			subject {
				id
			}
			picture {
				id
				name
				url
				secret
			}
			userMyFox {
				id
				customers {
					id
					subjectId
				}
			}
		}
		note
		paidAt
		calendar {
			id
			note
			groupClass
			from
			calendarToCustomers {
				id
				bill {
					id
				}
				bills {
					id
				}
				customer {
					id
					name
					surname
				}
				bail {
					id
					amount
					state
					dueTo
					transactionId
					subjectToShop {
						id
						subject {
							id
						}
					}
				}
			}
		}
		shop {
			id
			name
			timezone
			phone
			email
			displayCustomer
			address {
				id
				street
				city
				zip
			}
			subjectToShops {
				id
				subject {
					id
					sendEmail
					sendSms
					vatPayer
				}
			}
		}
		subject {
			id
			name
			ico
			dic
			vatPayer
			currency
			locale
			address {
				id
				street
				city
				zip
			}
		}
		employee {
			id
			receiptName
			color
			userMyFox {
				id
				name
				surname
				email
				phone
				address
			}
			shops {
				id
			}
			subject {
				id
				name
				ico
				dic
				vatPayer
				currency
				locale
				address {
					id
					street
					city
					zip
				}
			}
		}
		issuedBy {
			id
			name
			surname
			email
			phone
			address
			employee {
				receiptName
			}
		}
		meta
		stornoAt
		updatedAt
		stornoBy {
			id
		}
		stornoBill {
			id
			paidAt
		}
		stornedBill {
			id
		}
		billPayments {
			id
			amount
			meta
			paymentType {
				id
				payType
			}
		}
		discount {
			id
			discount_total
			discount_percent
			createdAt
			updatedAt
			meta
		}
		state
		totalPrice
	}
`;export const calendarToCustomerFragment=gql`
	fragment calendarToCustomer on CalendarToCustomer {
		id
		capacity
		note
		customer {
			id
			name
			surname
			email
			phone
			address
			locale
			ean
			picture {
				id
				name
				url
				secret
			}
			subject {
				id
			}
			userMyFox {
				id
			}
		}
		calendar {
			id
		}
		bill {
			...bill
		}
		bills {
			id
			...bill
		}
		state
		payState
		bail {
			...customersBail
		}
	}
	${billFragment}
	${customersBailFragment}
`;export const calendarFragment=gql`
	fragment calendar on Calendar {
		id
		from
		to
		state
		note
		paidAt
		payType
		billNumber
		bills {
			id
			billPayments {
				id
			}
		}
		groupClass
		capacity
		shop {
			id
			name
			timezone
			phone
			email
			address {
				id
				street
				city
				zip
			}
			subjectToShops {
				id
				subject {
					id
					sendEmail
					sendSms
				}
			}
		}
		subject {
			id
			name
			ico
			vatPayer
			currency
			locale
		}
		carts {
			id
			name
			count
			vat
			price
			priceVat
			createdAt
			createdBy {
				id
				name
				surname
				email
				phone
				address
			}
			updatedAt
			updatedBy {
				id
				name
				surname
				email
				phone
				address
			}
			item {
				id
				duration
				pauseAfter
				name
				price
				priceVat
				vat
				color
				note
				snippetDisabled
				stock
				stockEnabled
				bailAmount
				isBailActive
				categories {
					id
				}
			}
		}
		customers {
			id
			name
			surname
			email
			phone
			address
			locale
			ean
			subject {
				id
			}
			picture {
				id
				name
				url
				secret
			}
			userMyFox {
				id
			}
		}
		calendarToCustomers {
			...calendarToCustomer
		}
		employees {
			id
			showCstContact
			color
			notifyPhone
			subject {
				id
			}
			shops {
				id
				name
				timezone
				phone
				email
				address {
					id
					street
					city
					zip
				}
				subjectToShops {
					id
					subject {
						id
						sendEmail
						sendSms
					}
				}
			}
			userMyFox {
				id
				name
				surname
				email
				phone
				address
				locale
				userPicture {
					id
					name
					url
					secret
				}
			}
		}
		createdAt
		createdBy {
			id
			name
			surname
			email
			phone
			address
		}
		updatedAt
		updatedBy {
			id
			name
			surname
			email
			phone
			address
		}
		issuedBy {
			id
			name
			surname
			email
			phone
			address
		}
		isOnlineReservation
	}
	${calendarToCustomerFragment}
`;export const selectedEmployeeFragment=gql`
	fragment selectedEmployeeFragment on Employee {
		id
		notifyPhone
		subject {
			id
		}
	}
`;export const featureFlagFragment=gql`
	fragment featureFlag on FeatureFlag {
		id
		name
		code
		data
	}
`;
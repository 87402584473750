import gql from'graphql-tag';export const listNotifyPatternsQuery=gql`
	query ListNotifyPatternsQuery {
		listNotifyPatterns {
			id
			subject
			text
			group
			event
			locale
			sendAtAfterNow
			sendAtBeforeEvent
			notSendBeforeEvent
			createdAt
			distributorId
			distributor {
				id
			}
		}
	}
`;export const createNotifypatMutation=gql`
	mutation CreateNotifyPatternMutation(
		$subject: String
		$text: String!
		$sendAtBeforeEvent: Int!
		$sendAtAfterNow: Int!
		$notSendBeforeEvent: Int!
		$group: NotifyGroups!
		$event: NotifyEvents!
		$locale: Locale
	) {
		createNotifyPattern(
			data: {
				subject: $subject
				text: $text
				sendAtBeforeEvent: $sendAtBeforeEvent
				sendAtAfterNow: $sendAtAfterNow
				notSendBeforeEvent: $notSendBeforeEvent
				group: $group
				event: $event
				locale: $locale
			}
		) {
			id
			subject
			text
			group
			event
			locale
			sendAtAfterNow
			sendAtBeforeEvent
			notSendBeforeEvent
			createdAt
		}
	}
`;export const updateNotifypatMutation=gql`
	mutation UpdateNotifyPatternMutation(
		$ntpId: String
		$subject: String
		$text: String!
		$sendAtBeforeEvent: Int!
		$sendAtAfterNow: Int!
		$notSendBeforeEvent: Int!
		$group: NotifyGroups!
		$event: NotifyEvents!
		$locale: Locale
	) {
		updateNotifyPattern(
			where: {id: $ntpId}
			data: {
				subject: $subject
				text: $text
				sendAtBeforeEvent: $sendAtBeforeEvent
				sendAtAfterNow: $sendAtAfterNow
				notSendBeforeEvent: $notSendBeforeEvent
				group: $group
				event: $event
				locale: $locale
			}
		) {
			id
			subject
			text
			group
			event
			locale
			sendAtAfterNow
			sendAtBeforeEvent
			notSendBeforeEvent
			createdAt
		}
	}
`;
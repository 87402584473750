import gql from'graphql-tag';import{openingFragment,openingHeadFragment}from'./Fragments';export const wholeShopOpeningsRangeQuery=gql`
	query WholeShopOpeningsRangeQuery($shopId: String!, $from: AWSDateTime!, $to: AWSDateTime!) {
		wholeShopOpeningsRange(where: {shopId: $shopId, from: $from, to: $to}) {
			shopId
			employeeId
			openings {
				id
				day
				from
				order
				to
				date
			}
		}
	}
`;export const existingOpeningHeaderQuery=gql`
	query ListOpeningHeadsQuery(
		$shopId: String!
		$employeeId: String
		$validFromStart: AWSDateTime!
		$validFromEnd: AWSDateTime!
	) {
		listOpeningHeads(
			where: {
				shopId: {equals: $shopId}
				employeeId: {equals: $employeeId}
				validFrom: {lte: $validFromEnd, gte: $validFromStart}
			}
		) {
			id
			validFrom
			employeeId
			__typename
		}
	}
`;export const createOpeningMutation=gql`
	mutation CreateOpeningMutation(
		$openingHeadId: OpeningHeadWhereUniqueInput
		$from: Float!
		$to: Float!
		$day: Int!
		$order: Int
		$odd: Boolean
	) {
		createOpening(
			data: {openingHead: {connect: $openingHeadId}, day: $day, from: $from, to: $to, order: $order, odd: $odd}
		) {
			...opening
		}
	}
	${openingFragment}
`;export const updateOpeningMutation=gql`
	mutation UpdateOpeningMutation($id: String!, $from: Float, $to: Float, $day: Int, $order: Int) {
		updateOpening(where: {id: $id}, data: {day: $day, from: $from, to: $to, order: $order}) {
			...opening
		}
	}
	${openingFragment}
`;export const deleteOpeningMutation=gql`
	mutation DeleteOpeningMutation($id: String!) {
		deleteOpening(where: {id: $id}) {
			id
		}
	}
`;export const getShopOpeningQuery=gql`
	query GetShopOpeningQuery($shopId: String!) {
		getShop(where: {id: $shopId}) {
			id
			name
			openingHeads {
				...openingHead
				__typename
			}
			employees {
				id
				color
				role
				ord
				notifyPhone
				showCstContact
				bookEnabled
				openingHeads {
					...openingHead
					__typename
				}
				userMyFox {
					id
					name
					surname
					__typename
				}

				subject {
					id
					__typename
				}
				__typename
			}
			__typename
		}
	}
	${openingHeadFragment}
`;export const createOpeningHeadMutation=gql`
	mutation CreateOpeningHeadMutation(
		$shopId: ShopWhereUniqueInput
		$employeeId: EmployeeWhereUniqueInput
		$validFrom: AWSDateTime!
		$employeeOwnOpening: Boolean
		$parity: Boolean
	) {
		createOpeningHead(
			data: {
				shop: {connect: $shopId}
				employee: {connect: $employeeId}
				validFrom: $validFrom
				employeeOwnOpening: $employeeOwnOpening
				parity: $parity
			}
		) {
			...openingHead
		}
	}
	${openingHeadFragment}
`;export const updateOpeningHeadMutation=gql`
	mutation UpdateOpeningHeadMutation(
		$id: String!
		$validFrom: AWSDateTime!
		$parity: Boolean
		$employeeOwnOpening: Boolean
	) {
		updateOpeningHead(
			where: {id: $id}
			data: {validFrom: $validFrom, parity: $parity, employeeOwnOpening: $employeeOwnOpening}
		) {
			...openingHead
		}
	}
	${openingHeadFragment}
`;export const deleteOpeningHeadMutation=gql`
	mutation DeleteOpeningHeadMutation($id: String!) {
		deleteOpeningHead(where: {id: $id}) {
			...openingHead
		}
	}
	${openingHeadFragment}
`;
import gql from'graphql-tag';import{addressFragment}from'./Fragments';export const createAddressMutation=gql`
	mutation CreateAddressMutation(
		$city: String
		$street: String
		$zip: String
		$longitude: Float
		$latitude: Float
		$subjectWhereUnique: SubjectWhereUniqueInput!
	) {
		createAddress(
			data: {
				city: $city
				street: $street
				zip: $zip
				longitude: $longitude
				latitude: $latitude
				subject: {connect: $subjectWhereUnique}
			}
		) {
			...address
		}
	}
	${addressFragment}
`;export const updateAddressMutation=gql`
	mutation UpdateAddressMutation(
		$addressId: String!
		$city: String
		$street: String
		$zip: String
		$longitude: Float
		$latitude: Float
	) {
		updateAddress(
			where: {id: $addressId}
			data: {city: $city, street: $street, zip: $zip, longitude: $longitude, latitude: $latitude}
		) {
			...address
		}
	}
	${addressFragment}
`;
import gql from'graphql-tag';export const listMenusQuery=gql`
	query ListMenusQuery($role: UserRoles!, $position: MenuPosition!, $type: MenuType!, $published: Boolean = true) {
		listMenus(
			where: {
				role: {equals: $role}
				position: {equals: $position}
				type: {equals: $type}
				published: {equals: $published}
			}
			orderBy: [{ord: ASC}]
		) {
			id
			title
			class
			img
			page
			color
		}
	}
`;
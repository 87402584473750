import gql from'graphql-tag';export const listEditorTabsQuery=gql`
	query ListEditorTabsQuery($userMyFoxId: String!) {
		listEditorTabs(where: {userMyFoxId: {equals: $userMyFoxId}}, orderBy: {tabIndex: ASC}) {
			id
			title
			tabIndex
			documents
			variables
			headers
		}
	}
`;export const createEditorTabMutation=gql`
	mutation CreateEditorTabMutation(
		$title: String!
		$tabIndex: Int!
		$documents: String!
		$variables: String
		$headers: String
		$userMyFoxId: String!
	) {
		createEditorTab(
			data: {
				title: $title
				tabIndex: $tabIndex
				documents: $documents
				variables: $variables
				headers: $headers
				userMyFox: {connect: {id: $userMyFoxId}}
			}
		) {
			id
		}
	}
`;export const updateEditorTabMutation=gql`
	mutation UpdateEditorTabMutation(
		$id: String!
		$title: String!
		$tabIndex: Int!
		$documents: String
		$headers: String
		$variables: String
	) {
		updateEditorTab(
			where: {id: $id}
			data: {title: $title, tabIndex: $tabIndex, documents: $documents, variables: $variables, headers: $headers}
		) {
			id
		}
	}
`;export const deleteEditorTabMutation=gql`
	mutation DeleteEditorTabMutation($id: String!) {
		deleteEditorTab(where: {id: $id}) {
			id
		}
	}
`;
import gql from'graphql-tag';export const getFileQuery=gql`
	query GetFileQuery($fileId: String!) {
		getFile(where: {id: $fileId}) {
			id
			secret
			customersPictures {
				id
			}
		}
	}
`;export const deleteFileMutation=gql`
	mutation DeleteFileMutation($fileId: String!) {
		deleteFile(where: {id: $fileId}) {
			id
		}
	}
`;export const createFileMutation=gql`
	mutation CreateFileMutation($name: String!, $size: Int!, $contentType: String!, $secret: String!, $url: String!) {
		createFile(data: {name: $name, size: $size, contentType: $contentType, secret: $secret, url: $url}) {
			id
			name
			contentType
			secret
			url
		}
	}
`;
import gql from'graphql-tag';export const isTestPaymentTypeEnabledQuery=gql`
	query IsTestPaymentTypeEnabledQuery($subjectId: String!) {
		listPaymentTypes(where: {subjectId: {equals: $subjectId}, payType: {equals: Test}, enabled: {equals: true}}) {
			id
			meta
			payType
			meta
			enabled
			ord
		}
	}
`;export const listPaymentTypesQuery=gql`
	query ListPaymentTypesQuery($subjectId: String!, $enabled: Boolean) {
		listPaymentTypes(where: {subjectId: {equals: $subjectId}, enabled: {equals: $enabled}}) {
			id
			meta
			payType
			meta
			enabled
			ord
		}
	}
`;export const paymentTypesMultiIcoQuery=gql`
	query PaymentTypesMultiIcoQuery($subjectsIds: [String!], $enabled: Boolean) {
		listPaymentTypes(where: {subjectId: {in: $subjectsIds}, enabled: {equals: $enabled}}) {
			subject {
				id
			}
			id
			meta
			payType
			enabled
			ord
		}
	}
`;export const updatePaymentTypesMutation=gql`
	mutation UpdatePaymentTypeMutation($id: String!, $enabled: Boolean, $meta: AWSJSON) {
		updatePaymentType(where: {id: $id}, data: {enabled: $enabled, meta: $meta}) {
			id
			meta
			payType
			meta
			enabled
			ord
		}
	}
`;export const createPaymentTypesMutation=gql`
	mutation CreatePaymentTypeMutation($subjectId: String!, $enabled: Boolean!, $payType: PayType!, $ord: Int!) {
		createPaymentType(
			data: {payType: $payType, ord: $ord, enabled: $enabled, subject: {connect: {id: $subjectId}}}
		) {
			id
		}
	}
`;
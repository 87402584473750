import gql from'graphql-tag';import{calendarFragment,calendarToCustomerFragment}from'./Fragments';export const typeOfCalendarsQuery=gql`
	query TypeOfCalendarsQuery(
		$subjectIds: [String!]
		$shopId: String
		$from: AWSDateTime
		$to: AWSDateTime
		$skip: Int
		$take: Int
	) {
		listCalendars(
			where: {shopId: {equals: $shopId}, subjectId: {in: $subjectIds}, from: {lte: $to}, to: {gte: $from}}
			orderBy: {from: DESC}
			skip: $skip
			take: $take
		) {
			id
			isOnlineReservation
		}
	}
`;export const listCalendarsQuery=gql`
	query ListCalendarsQuery(
		$shopId: String
		$states: [CalendarState!]
		$from: AWSDateTime
		$to: AWSDateTime
		$employeeWhereInput: EmployeeWhereInputWithoutNullables
		$take: Int
		$skip: Int
	) {
		listCalendars(
			where: {
				shopId: {equals: $shopId}
				state: {in: $states}
				to: {gte: $from}
				from: {lte: $to}
				employees: {some: $employeeWhereInput}
			}
			orderBy: [{from: ASC}]
			take: $take
			skip: $skip
		) {
			id
			groupClass
			state
			from
			to
			note
			capacity
			customers {
				id
				name
				surname
				email
				phone
				subject {
					id
				}
				picture {
					id
					secret
				}
			}
			calendarToCustomers {
				id
				state
				payState
				capacity
				customer {
					id
					name
					surname
					email
					phone
					subject {
						id
					}
					picture {
						id
						secret
					}
				}
				bail {
					id
					state
					paidAt
					updatedAt
					refundUntil
				}
				bills {
					id
					state
					paidAt
				}
			}
			employees {
				id
				color
				userMyFox {
					id
					name
					surname
					email
					phone
				}
			}
			bills {
				id
				state
				paidAt
				note
			}
			carts {
				id
				name
				count
				priceVat
				item {
					id
					name
					price
					priceVat
					vat
					color
				}
			}
		}
	}
`;export const getCreateCalendarMutation=(from,to,shopId,employeeId,subjectId,cartsFull,capacity)=>{const variables={from,to,conShop:{id:shopId},conEmpl:[{id:employeeId}],conSubj:{id:subjectId},capacity};const carts=cartsFull.map(cart=>{return`{
            name: "${cart.name}"
            price: ${cart.price}
            priceVat: ${cart.priceVat}
            vat: ${cart.vat}
            item: { connect: { id: "${cart.id}" } }
        }`;}).join(',');const mutation=gql`
        mutation GetCreateCalendarMutation($from: AWSDateTime, $to: AWSDateTime, $conShop: ShopWhereUniqueInput!, $conSubj: SubjectWhereUniqueInput, $conEmpl: [EmployeeWhereUniqueInput!], $capacity: Int) {
            createCalendar (
                data: {
                    from: $from
                    to: $to
                    state: Open
                    groupClass: true
                    capacity: $capacity
                    carts: {
                        create: [${carts}]
                    }
                    shop: {
                        connect: $conShop
                    }
                    subject: {
                        connect: $conSubj
                    }
                    employees: {
                        connect: $conEmpl
                    }
                }
            ) {
                id
            }
        }
    `;return{variables,mutation};};export const getCalendarQuery=gql`
	query GetCalendarQuery($calendarId: String) {
		getCalendar(where: {id: $calendarId}) {
			id
			from
			to
			state
			note
			capacity
			groupClass
			paidAt
			carts {
				id
				name
				priceVat
				count
				item {
					id
					name
					priceVat
					price
					vat
					color
				}
			}
			bills {
				id
				note
				state
				paidAt
				totalPrice
			}
			shop {
				id
				timezone
			}
			subject {
				id
			}
			customers {
				id
			}
			calendarToCustomers {
				id
				capacity
				payState
				state
				bail {
					id
					state
					amount
					paidAt
					refundUntil
					updatedAt
				}
				customer {
					id
					name
					surname
					phone
					email
					userMyFox {
						id
					}
					picture {
						id
						secret
					}
				}
				calendar {
					id
				}
			}
			employees {
				id
				color
				shops {
					id
				}
				userMyFox {
					id
					name
					surname
				}
			}
		}
	}
`;export const customerOrEmployeeEventsQuery=gql`
	query EventsQuery($customersIds: [String!], $employeeId: String, $subjectsIds: [String!]) {
		events(where: {customersIds: $customersIds, employeeId: $employeeId, subjectsIds: $subjectsIds}) {
			id
			billId
			from
			dataOrigin
		}
	}
`;export const customerOrEmployeeEventsHistoryQuery=gql`
	query EventsHistoryQuery($customersIds: [String!], $employeeId: String, $subjectsIds: [String!]) {
		eventsHistory(where: {customersIds: $customersIds, employeeId: $employeeId, subjectsIds: $subjectsIds}) {
			id
			billId
			from
			dataOrigin
		}
	}
`;export const createCalendarToCustomerMutation=gql`
	mutation CreateCalendarToCustomerMutation(
		$customerId: CustomerWhereUniqueInput
		$calendarId: CalendarWhereUniqueInput
		$createdBy: UserMyFoxWhereUniqueInput
		$capacity: Int
		$state: VisitState
	) {
		createCalendarToCustomer(
			data: {
				customer: {connect: $customerId}
				calendar: {connect: $calendarId}
				capacity: $capacity
				updatedBy: {connect: $createdBy}
				state: $state
			}
		) {
			...calendarToCustomer
		}
	}
	${calendarToCustomerFragment}
`;export const updateCalendarToCustomerMutation=gql`
	mutation UpdateCalendarToCustomerMutation(
		$id: String!
		$bill: BillWhereUniqueInput
		$billIds: [BillWhereUniqueInput!]
		$state: VisitState
		$payState: PayState
	) {
		updateCalendarToCustomer(
			where: {id: $id}
			data: {bill: {connect: $bill}, state: $state, payState: $payState, bills: {connect: $billIds}}
		) {
			...calendarToCustomer
		}
	}
	${calendarToCustomerFragment}
`;export const deleteCalendarToCustomerMutation=gql`
	mutation DeleteCalendarToCustomerMutation($id: String!) {
		deleteCalendarToCustomer(where: {id: $id}) {
			id
		}
	}
`;export const createCalendarMutationNew=gql`
	mutation CreateCalendarGroupMutation(
		$from: AWSDateTime
		$to: AWSDateTime
		$state: CalendarState!
		$notifiesState: ProcessingState
		$shopId: String!
		$subjectId: String
		$employeesIds: [EmployeeWhereUniqueInput!]
		$customersIds: [CustomerWhereUniqueInput!]
		$carts: [CartCreateWithoutCalendarInput!]
		$calendarToCustomers: [CalendarToCustomerCreateWithoutCalendarInput!]
		$note: String
		$groupClass: Boolean
		$capacity: Int
	) {
		createCalendar(
			data: {
				from: $from
				to: $to
				state: $state
				notifiesState: $notifiesState
				shop: {connect: {id: $shopId}}
				subject: {connect: {id: $subjectId}}
				carts: {create: $carts}
				calendarToCustomers: {create: $calendarToCustomers}
				note: $note
				employees: {connect: $employeesIds}
				customers: {connect: $customersIds}
				groupClass: $groupClass
				capacity: $capacity
			}
		) {
			...calendar
		}
	}
	${calendarFragment}
`;export const createCalendarMutation=gql`
	mutation CreateCalendarMutation(
		$from: AWSDateTime
		$to: AWSDateTime
		$state: CalendarState!
		$notifiesState: ProcessingState
		$shopId: String!
		$subjectId: String
		$employeeId: String
		$note: String
	) {
		createCalendar(
			data: {
				from: $from
				to: $to
				state: $state
				notifiesState: $notifiesState
				shop: {connect: {id: $shopId}}
				subject: {connect: {id: $subjectId}}
				note: $note
				employees: {connect: [{id: $employeeId}]}
			}
		) {
			...calendar
		}
	}
	${calendarFragment}
`;export const updateCalendarMutation=gql`
	mutation UpdateCalendarMutation(
		$calendarId: String!
		$from: AWSDateTime
		$to: AWSDateTime
		$note: String
		$cartsIds: [CartWhereUniqueInput!]
		$employeesIds: [EmployeeWhereUniqueInput!]
		$customersIds: [CustomerWhereUniqueInput!]
		$carts: [CartCreateWithoutCalendarInput!]
		$state: CalendarState
		$notifiesState: ProcessingState
		$paidAt: AWSDateTime
		$payType: PayType
		$billNumber: String
		$subjectConnectId: SubjectWhereUniqueInput
		$groupClass: Boolean
		$capacity: Int
	) {
		updateCalendar(
			where: {id: $calendarId}
			data: {
				from: $from
				to: $to
				state: $state
				notifiesState: $notifiesState
				subject: {connect: $subjectConnectId}
				note: $note
				carts: {create: $carts, set: $cartsIds}
				employees: {set: $employeesIds}
				customers: {set: $customersIds}
				paidAt: $paidAt
				payType: $payType
				billNumber: $billNumber
				groupClass: $groupClass
				capacity: $capacity
			}
		) {
			...calendar
		}
	}
	${calendarFragment}
`;export const deleteBillDiscountMutation=gql`
	mutation DeleteCalPaymentMutation($id: String!) {
		deleteDiscount(where: {id: $id}) {
			id
		}
	}
`;export const updateCalendarBillMutation=gql`
	mutation UpdateCalendarBillMutation($calendarId: String!, $bill: CalendarUpdateInput!) {
		updateCalendar(where: {id: $calendarId}, data: $bill) {
			id
			bill
		}
	}
`;export const deleteCalendarMutation=gql`
	mutation DeleteCalendarMutation($calendarId: String!) {
		deleteCalendar(where: {id: $calendarId}) {
			id
			from
			state
		}
	}
`;export const loadBillQuery=gql`
	query LoadBillQuery($calendarId: String!) {
		getCalendar(where: {id: $calendarId}) {
			id
			bill
			billNumber
			subject {
				id
			}
		}
	}
`;export const loadBillJsonFromCtcQuery=gql`
	query LoadBillJsonFromCtcQuery($ctcId: String!) {
		getCalendarToCustomer(where: {id: $ctcId}) {
			id
			bill {
				billNumber
			}
			subject {
				id
			}
		}
	}
`;export const checkConflictQuery=gql`
	query CheckConflictQuery($employeeId: String!, $shopId: String!, $from: AWSDateTime!, $to: AWSDateTime!) {
		listCalendars(
			where: {
				shopId: {equals: $shopId}
				employees: {some: {id: {equals: $employeeId}}}
				state: {equals: Open}
				OR: [{from: {lte: $from}, to: {gt: $from}}, {from: {lt: $to}, to: {gte: $to}}]
			}
		) {
			id
			from
			to
		}
	}
`;export const cancelMassMutation=gql`
	mutation CancelMassMutation($calendarsIds: [String!]!, $reason: String!) {
		cancelMass(data: {calendarsIds: $calendarsIds, reason: $reason}) {
			success
		}
	}
`;export const firstCalendarsForSubject=gql`
	query FirstCalendarsForSubject($subjectId: String!) {
		listCalendars(where: {subjectId: {equals: $subjectId}}, orderBy: [{createdAt: ASC}], take: 1) {
			id
			createdAt
		}
	}
`;
import gql from'graphql-tag';export const listItemCategoriesQuery=gql`
	query ListItemCategoriesQuery($shopId: String!) {
		listItemCategories(where: {shopId: {equals: $shopId}}, orderBy: {ord: ASC}) {
			id
			name
			color
			descriptionForNotification
			code
			items {
				id
				name
			}
			ord
			visible
		}
	}
`;export const createItemCategoryMutation=gql`
	mutation CreateItemCategoryMutation(
		$name: String!
		$color: String!
		$descriptionForNotification: String
		$shopId: ShopWhereUniqueInput!
		$ord: Int
		$visible: Boolean
	) {
		createItemCategory(
			data: {
				name: $name
				color: $color
				descriptionForNotification: $descriptionForNotification
				shop: {connect: $shopId}
				ord: $ord
				visible: $visible
			}
		) {
			id
			name
			color
			descriptionForNotification
			code
			items {
				id
				name
			}
			ord
			visible
		}
	}
`;export const updateItemCategoryMutation=gql`
	mutation UpdateItemCategoryMutation(
		$id: String!
		$name: String
		$color: String
		$descriptionForNotification: String
		$shopId: ShopWhereUniqueInput
		$ord: Int
		$visible: Boolean
	) {
		updateItemCategory(
			where: {id: $id}
			data: {
				name: $name
				color: $color
				descriptionForNotification: $descriptionForNotification
				shop: {connect: $shopId}
				ord: $ord
				visible: $visible
			}
		) {
			id
			name
			color
			descriptionForNotification
			items {
				id
				name
			}
			ord
			visible
		}
	}
`;export const deleteItemCategoryMutation=gql`
	mutation DeleteItemCategoryMutation($id: String!) {
		deleteItemCategory(where: {id: $id}) {
			id
		}
	}
`;
import gql from'graphql-tag';import{itemFragment,userMyFoxFragment}from'./Fragments';export const createCartMutation=gql`
	mutation CreateCartMutation(
		$itemId: ItemWhereUniqueInput
		$count: Int
		$name: String!
		$price: Float!
		$priceVat: Float!
		$vat: Float!
		$calendarId: CalendarWhereUniqueInput
		$billId: BillWhereUniqueInput
	) {
		createCart(
			data: {
				item: {connect: $itemId}
				count: $count
				name: $name
				price: $price
				priceVat: $priceVat
				vat: $vat
				calendar: {connect: $calendarId}
				bill: {connect: $billId}
			}
		) {
			id
			name
			count
			price
			priceVat
			vat
			createdAt
			createdBy {
				...userMyFox
			}
			updatedAt
			updatedBy {
				...userMyFox
			}
			item {
				...item
			}
		}
	}
	${itemFragment}
	${userMyFoxFragment}
`;export const updateCartMutation=gql`
	mutation UpdateCartMutation(
		$cartId: String!
		$count: Int
		$name: String
		$price: Float
		$priceVat: Float
		$vat: Float!
		$calendarId: CalendarWhereUniqueInput
	) {
		updateCart(
			where: {id: $cartId}
			data: {
				count: $count
				name: $name
				price: $price
				priceVat: $priceVat
				vat: $vat
				calendar: {connect: $calendarId}
			}
		) {
			id
			name
			count
			price
			priceVat
			vat
			createdAt
			createdBy {
				...userMyFox
			}
			updatedAt
			updatedBy {
				...userMyFox
			}
			item {
				...item
			}
			calendar {
				id
			}
		}
	}
	${itemFragment}
	${userMyFoxFragment}
`;export const deleteCartMutation=gql`
	mutation DeleteCartMutation($cartId: String!) {
		deleteCart(where: {id: $cartId}) {
			id
		}
	}
`;
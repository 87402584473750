import gql from'graphql-tag';import{noteFragment}from'./Fragments';export const listCustomersNotesQuery=gql`
	query ListCustomerNotesQuery($customerId: String!) {
		listNotes(where: {customerId: {equals: $customerId}}) {
			...note
		}
	}
	${noteFragment}
`;export const createNoteMutation=gql`
	mutation CreateNoteMutation(
		$customerId: CustomerWhereUniqueInput!
		$text: String!
		$imagesIds: [FileWhereUniqueInput!]
	) {
		createNote(data: {customer: {connect: $customerId}, text: $text, pin: null, images: {connect: $imagesIds}}) {
			id
			text
			pin
			createdAt
			images {
				id
				name
				url
				secret
			}
			createdBy {
				id
				name
				surname
				employee {
					id
					color
				}
			}
		}
	}
`;export const updateNoteMutation=gql`
	mutation UpdateNoteMutation(
		$noteId: String!
		$text: String
		$pin: AWSDateTime
		$imagesIds: [FileWhereUniqueInput!]
	) {
		updateNote(where: {id: $noteId}, data: {text: $text, pin: $pin, images: {set: $imagesIds}}) {
			id
			text
			pin
			createdAt
			images {
				id
				name
				url
				secret
			}
			createdBy {
				id
				name
				surname
				employee {
					id
					color
				}
			}
		}
	}
`;export const deleteNoteMutation=gql`
	mutation DeleteNoteMutation($noteId: String!) {
		deleteNote(where: {id: $noteId}) {
			id
		}
	}
`;
import gql from'graphql-tag';import{userMyFoxFragment}from'./Fragments';export const getUserMyFoxQuery=gql`
	query GetUserMyFoxQuery($id: String!) {
		getUserMyFox(where: {id: $id}) {
			...userMyFox
			partners {
				id
				role
				distributors {
					id
					name
				}
			}
		}
	}
	${userMyFoxFragment}
`;export const getUserMyFoxQueryBySubject=gql`
	query GetUserMyFoxQueryBySubject($id: String!) {
		getUserMyFox(where: {id: $id}) {
			id
			name
			surname
			changePassReq
			employee {
				id
			}
		}
	}
`;export const createUserMyFoxMutation=gql`
	mutation CreateUserMyFoxMutation(
		$name: String
		$email: AWSEmail
		$phone: String
		$surname: String
		$address: String
		$authIdCognito: String
		$iCalId: String
		$locale: Locale
	) {
		createUserMyFox(
			data: {
				iCalId: $iCalId
				name: $name
				email: $email
				phone: $phone
				surname: $surname
				address: $address
				authIdCognito: $authIdCognito
				locale: $locale
			}
		) {
			...userMyFox
		}
	}
	${userMyFoxFragment}
`;export const updateUserMyFoxMutation=gql`
	mutation UpdateUserMyFoxMutation(
		$userMyFoxId: String!
		$email: AWSEmail
		$name: String
		$phone: String
		$surname: String
		$address: String
		$userPictureId: FileWhereUniqueInput
		$locale: Locale
	) {
		updateUserMyFox(
			where: {id: $userMyFoxId}
			data: {
				email: $email
				name: $name
				phone: $phone
				surname: $surname
				address: $address
				locale: $locale
				userPicture: {connect: $userPictureId}
			}
		) {
			...userMyFox
		}
	}
	${userMyFoxFragment}
`;export const updateUserMFPassMutation=gql`
	mutation UpdateUserMFPassMutation($userMyFoxId: String!) {
		updateUserMyFox(
			where: {id: $userMyFoxId}
			data: {changePassReq: false, updatedBy: {connect: {id: $userMyFoxId}}}
		) {
			...userMyFox
		}
	}
	${userMyFoxFragment}
`;export const changeEmailMutation=gql`
	mutation ChangeEmailMutation($userMyFoxId: String!, $email: AWSEmail!) {
		changeEmail(where: {userMyFoxId: $userMyFoxId}, data: {email: $email}) {
			message
			success
		}
	}
`;export const attachEmailMutation=gql`
	mutation AttachEmailMutation($userMyFoxId: String!, $email: AWSEmail!, $password: String!) {
		attachEmail(where: {userMyFoxId: $userMyFoxId}, data: {email: $email, password: $password}) {
			message
			success
		}
	}
`;export const detachEmailMutation=gql`
	mutation detachEmailMutation($userMyFoxId: String!) {
		detachEmail(where: {userMyFoxId: $userMyFoxId}) {
			message
			success
		}
	}
`;export const makeEmailMutation=gql`
	mutation MakeEmailMutation($email: AWSEmail!, $password: String!) {
		makeEmail(data: {email: $email, password: $password}) {
			message
			success
			sub
		}
	}
`;
import styled from'styled-components';export const GreenButton=styled.button`
	color: #ffffff;
	background: #2ca63d;
	border: 1px solid #2ca63d;
	font-size: 16px;
	padding: 8px 16px;
	border-radius: 8px;
	cursor: pointer;
	transition: all ease-in 0.2s;
	box-shadow: none;
	border-bottom-right-radius: ${_ref=>{let{withoutFullBorderRadius}=_ref;return withoutFullBorderRadius?0:'8px';}};
	border-top-right-radius: ${_ref2=>{let{withoutFullBorderRadius}=_ref2;return withoutFullBorderRadius?0:'8px';}};
	border-right: 1px solid white;

	&:hover {
		color: #2ca63d;
		background: white;
		border: 1px solid #2ca63d;
		opacity: 0.8;
	}
`;
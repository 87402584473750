import gql from'graphql-tag';import{userMyFoxFragment,itemFragment,openingHeadFragment,subjectFragment}from'./Fragments';export const listEmployeesQuery=gql`
	query ListEmployeeQuery($shopId: [String!], $subjectsIds: [String!], $bookEnabled: Boolean) {
		listEmployees(
			where: {
				shops: {some: {id: {in: $shopId}}}
				subjectId: {in: $subjectsIds}
				bookEnabled: {equals: $bookEnabled}
			}
			orderBy: {ord: ASC}
		) {
			id
			color
			role
			ord
			notifyPhone
			showCstContact
			bookEnabled
			receiptName
			createdAt
			userMyFox {
				...userMyFox
			}
			shops {
				id
			}
			subjectId
			subject {
				...subject
			}
			openingHeads {
				...openingHead
			}
		}
	}
	${userMyFoxFragment}
	${openingHeadFragment}
	${subjectFragment}
`;export const getEmployeeQuery=gql`
	query GetEmployeeQuery($employeeId: String!) {
		getEmployee(where: {id: $employeeId}) {
			id
			color
			createdAt
			role
			ord
			notifyPhone
			receiptName
			showCstContact
			bookEnabled
			userMyFox {
				...userMyFox
			}
			shops {
				id
				name
				address {
					id
					street
					city
				}
			}
			subject {
				...subject
			}
		}
	}
	${userMyFoxFragment}
	${subjectFragment}
`;export const postLoginQuery=gql`
	query PostLoginQuery($id: String!) {
		getEmployee(where: {id: $id}) {
			id
			userMyFox {
				id
				locale
				partners {
					id
					role
				}
			}
			shops {
				id
				timezone
			}
			subject {
				id
				currency
				locale
				country
			}
		}
	}
`;export const hasEmployeeCurrentReservationQuery=gql`
	query HasEmployeeCurrentReservationQuery($employeeId: String!) {
		hasEmployeeCurrentReservation(where: {employeeId: $employeeId}) {
			currentReservationExist
			numberOfReservations
			error
		}
	}
`;export const createEmployeeMutation=gql`
	mutation CreateEmployeeMutation(
		$userMyFoxId: UserMyFoxWhereUniqueInput!
		$shopsIds: [ShopWhereUniqueInput!]
		$subjectId: SubjectWhereUniqueInput
		$role: UserRoles!
		$ord: Int
		$notifyPhone: String
		$showCstContact: Boolean!
		$bookEnabled: Boolean!
		$color: String
		$receiptName: String
	) {
		createEmployee(
			data: {
				userMyFox: {connect: $userMyFoxId}
				shops: {connect: $shopsIds}
				subject: {connect: $subjectId}
				role: $role
				ord: $ord
				notifyPhone: $notifyPhone
				showCstContact: $showCstContact
				bookEnabled: $bookEnabled
				color: $color
				receiptName: $receiptName
			}
		) {
			id
			color
			createdAt
			role
			ord
			notifyPhone
			showCstContact
			bookEnabled
			userMyFox {
				...userMyFox
			}
			openingHeads {
				...openingHead
			}
			shops {
				id
				name
				address {
					id
					street
					city
				}
			}
			subject {
				id
				ico
				name
			}
			calendars {
				from
				to
				state
			}
			receiptName
			items {
				...item
			}
		}
	}
	${userMyFoxFragment}
	${openingHeadFragment}
	${itemFragment}
`;export const updateEmployeeMutation=gql`
	mutation UpdateEmployeeMutation(
		$employeeId: String!
		$role: UserRoles
		$ord: Int
		$notifyPhone: String
		$receiptName: String
		$color: String
		$shopsIds: [ShopWhereUniqueInput!]
		$subjectId: SubjectWhereUniqueInput
		$showCstContact: Boolean
		$bookEnabled: Boolean
	) {
		updateEmployee(
			where: {id: $employeeId}
			data: {
				shops: {set: $shopsIds}
				subject: {connect: $subjectId}
				role: $role
				ord: $ord
				notifyPhone: $notifyPhone
				receiptName: $receiptName
				color: $color
				showCstContact: $showCstContact
				bookEnabled: $bookEnabled
			}
		) {
			id
			createdAt
			color
			role
			ord
			notifyPhone
			receiptName
			showCstContact
			bookEnabled
			userMyFox {
				...userMyFox
			}
			items {
				...item
			}
			subject {
				id
				name
				marketingName
				ico
			}
		}
	}
	${userMyFoxFragment}
	${itemFragment}
`;export const eraseEmployeeMutation=gql`
	mutation EraseEmployeeMutation($employeeId: String!, $currentEmployeeId: String!) {
		eraseEmployee(where: {employeeId: $employeeId, currentEmployeeId: $currentEmployeeId}) {
			success
			message
		}
	}
`;export const rolesQuery=gql`
	query {
		__type(name: "UserRoles") {
			name
			kind
			enumValues {
				name
				description
			}
		}
	}
`;
import gql from'graphql-tag';export const listChangeLogsQuery=gql`
	query ListChangeLogsQuery($visible: Boolean) {
		listChangeLogs(where: {visible: $visible}) {
			id
			title
			description
			visible
		}
	}
`;export const getChangeLogQuery=gql`
	query GetChangeLogsQuery($changeLogId: String!) {
		getChangeLog(where: {id: $changeLogId}) {
			id
			title
			description
		}
	}
`;export const getLastUncheckedChangeLogQuery=gql`
	query GetLastUncheckedChangeLogQuery {
		getLastUncheckedChangeLog {
			id
			title
			description
		}
	}
`;export const createChangeLogMutation=gql`
	mutation CreateChangeLogMutation($title: String!, $description: String!) {
		createChangeLog(data: {title: $title, description: $description, visible: false}) {
			id
		}
	}
`;export const updateChangeLogMutation=gql`
	mutation UpdateChangeLogMutation(
		$changeLogId: String!
		$description: String
		$title: String
		$connectOneEmployeeId: ChangeLogHiddenByEmployeesUpdateNestedInput
	) {
		updateChangeLog(
			where: {id: $changeLogId}
			data: {title: $title, description: $description, hiddenByEmployees: $connectOneEmployeeId}
		) {
			id
		}
	}
`;export const publishChangeLogMutation=gql`
	mutation PublishChangeLogMutation($changeLogId: String!) {
		updateChangeLog(where: {id: $changeLogId}, data: {visible: true, hiddenByEmployees: {set: []}}) {
			id
		}
	}
`;export const deleteChangeLogMutation=gql`
	mutation DeleteChangeLogMutation($changeLogId: String!) {
		deleteChangeLog(where: {id: $changeLogId}) {
			id
		}
	}
`;
import gql from'graphql-tag';import{itemFragment}from'./Fragments';export const listItemsQuery=gql`
	query ListItemsQuery($shopId: String!, $deleted: Boolean = false) {
		listItems(where: {shopId: {equals: $shopId}, deleted: {equals: $deleted}}, orderBy: {ord: ASC}) {
			...item
			employees {
				id
				color
				userMyFox {
					id
					name
					surname
				}
			}
		}
	}
	${itemFragment}
`;// * Neobsahuje flag deleted! Jenom pro pouziti, kde jsou potreba smazane polozky!
export const allItemsQuery=gql`
	query ListItemsQuery($shopId: String!, $skip: Int, $take: Int) {
		listItems(where: {shopId: {equals: $shopId}}, orderBy: [{ord: ASC}], skip: $skip, take: $take) {
			...item
			employees {
				id
				color
				userMyFox {
					id
					name
					surname
				}
			}
		}
	}
	${itemFragment}
`;export const maxOrdQuery=gql`
	query MaxOrdQuery($shopId: String!) {
		listItems(where: {shopId: {equals: $shopId}}, orderBy: [{ord: DESC}], take: 1) {
			id
			ord
		}
	}
`;export const getItemQuery=gql`
	query GetItemsQuery($itemId: String!) {
		getItem(where: {id: $itemId}) {
			...item
			employees {
				id
				color
				userMyFox {
					id
					name
					surname
				}
			}
		}
	}
	${itemFragment}
`;export const createItemMutation=gql`
	mutation CreateItemMutation(
		$name: String!
		$color: String!
		$code: String
		$duration: Int
		$pauseAfter: Int
		$price: Float
		$priceVat: Float
		$vat: Float!
		$stock: Float
		$shopId: ShopWhereUniqueInput!
		$ean: String
		$note: String
		$snippetDisabled: Boolean
		$ord: Int
		$type: ItemTypes
		$meta: AWSJSON
		$employeesIds: [EmployeeWhereUniqueInput!]
		$categoriesIds: [ItemCategoryWhereUniqueInput!]
		$pictConn: FileWhereUniqueInput
		$isBailActive: Boolean
		$bailAmount: Int
	) {
		createItem(
			data: {
				name: $name
				color: $color
				code: $code
				duration: $duration
				pauseAfter: $pauseAfter
				price: $price
				priceVat: $priceVat
				vat: $vat
				stock: $stock
				type: $type
				meta: $meta
				isBailActive: $isBailActive
				bailAmount: $bailAmount
				shop: {connect: $shopId}
				ean: $ean
				note: $note
				snippetDisabled: $snippetDisabled
				ord: $ord
				employees: {connect: $employeesIds}
				categories: {connect: $categoriesIds}
				picture: {connect: $pictConn}
			}
		) {
			...item
			employees {
				id
				color
				userMyFox {
					id
					name
					surname
				}
			}
		}
	}
	${itemFragment}
`;export const updateItemMutation=gql`
	mutation UpdateItemMutation(
		$itemId: String!
		$name: String
		$color: String
		$duration: Int
		$pauseAfter: Int
		$price: Float
		$priceVat: Float
		$vat: Float
		$stock: Float
		$note: String
		$snippetDisabled: Boolean
		$ord: Int
		$ean: String
		$employeesIds: [EmployeeWhereUniqueInput!]
		$categoriesIds: [ItemCategoryWhereUniqueInput!]
		$type: ItemTypes
		$meta: AWSJSON
		$pictConn: FileWhereUniqueInput
		$isBailActive: Boolean
		$bailAmount: Int
	) {
		updateItem(
			where: {id: $itemId}
			data: {
				name: $name
				color: $color
				duration: $duration
				pauseAfter: $pauseAfter
				price: $price
				priceVat: $priceVat
				vat: $vat
				stock: $stock
				ean: $ean
				note: $note
				snippetDisabled: $snippetDisabled
				ord: $ord
				type: $type
				meta: $meta
				isBailActive: $isBailActive
				bailAmount: $bailAmount
				employees: {set: $employeesIds}
				categories: {set: $categoriesIds}
				picture: {connect: $pictConn}
			}
		) {
			...item
			carts {
				id
				calendar {
					id
					state
				}
			}
			employees {
				id
				color
				userMyFox {
					id
					name
					surname
				}
			}
		}
	}
	${itemFragment}
`;export const updateStockItemMutation=gql`
	mutation UpdateStockItemMutation($itemId: String!, $stock: Float) {
		updateItem(where: {id: $itemId}, data: {stock: $stock}) {
			id
			stock
		}
	}
`;export const deleteItemMutation=gql`
	mutation UpdateItemMutation($itemId: String!) {
		updateItem(where: {id: $itemId}, data: {deleted: true}) {
			id
			deleted
		}
	}
`;
import styled,{createGlobalStyle,css}from'styled-components';import ReactTooltip from'react-tooltip';import TextField from'@mui/material/TextField';import Select from'@mui/material/Select';import FormControl from'@mui/material/FormControl';const openSansFontFamily=css`
	font-family: 'Open Sans', serif;
`;export const SubSectionTitle=styled.h2`
	${openSansFontFamily};

	margin: 15px 0;
	font-size: 18px;
	font-weight: 400;
	color: #48494a;
`;export const Label=styled.span`
	${openSansFontFamily};

	padding-bottom: 10px;
	font-size: ${_ref=>{let{fontSize}=_ref;return fontSize?fontSize:'14px;';}};
	font-weight: ${_ref2=>{let{bolder}=_ref2;return bolder?'bold':'normal';}};
	color: ${_ref3=>{let{color}=_ref3;return color?color:'#2ca63d;';}};
`;export const Paragraph=styled.p`
	${openSansFontFamily};
	font-size: 14px;
	line-height: 22px;
	color: #000;
`;export const Divider=styled.div`
	width: 100%;
	border-bottom: 1px solid #dae2e6;
	padding-top: 20px;
	margin-bottom: 20px;
`;export const Button=styled.button`
    ${_ref4=>{let{variant}=_ref4;return variant==='primary'&&'background-color: #2ca63d;';}}
    ${_ref5=>{let{variant}=_ref5;return variant==='secondary'&&'background-color: #0e52a4;';}}
    ${_ref6=>{let{variant}=_ref6;return variant==='stripe'&&'background-color: #293CE1;';}}

	${_ref7=>{let{variant}=_ref7;switch(variant){case'primary':default:return'background-color: #2ca63d;';case'secondary':return'background-color: #0e52a4;';case'stripe':return'background-color: #293CE1;';}}}

	border: none;
    border-radius: 7px;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    margin-right: 15px;
    margin-top: 15px;
    min-width: 200px;
    font-size: 16px;
    font-weight: bold;
    padding: 15px 30px;
    text-decoration: none;

    ${props=>props.disabled&&'background-color: gray;'};
  }

  &:hover {
    ${_ref8=>{let{variant}=_ref8;return variant==='primary'&&'background-color: #3dc420;';}}
    ${_ref9=>{let{variant}=_ref9;return variant==='secondary'&&'background-color: #1d60ad;';}}
    ${props=>props.disabled&&'background-color: gray;'};
  }
`;export const CenteredContainer=styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;

	${_ref10=>{let{horizontal}=_ref10;return horizontal&&'flex-direction: column;';}}

	button {
		margin: 0 0 0 10px;
	}
`;export const MediumInput=styled.input`
	height: 16px;
	width: 16px;
`;export const Tooltip=styled(ReactTooltip)`
	font-family: 'Open Sans', serif;
	position: fixed;
	max-width: 260px;
	padding: 10px;
	border-radius: 7px;
	font-size: 13px;
	text-decoration: none;
	font-style: normal;
	z-index: 2;
`;export const NoTopMarginSubSectionTitle=styled(SubSectionTitle)`
	margin-top: 0;
`;export const Step3Wrapper=styled.div`
	display: flex;

	@media (max-width: 767px) {
		flex-direction: column;
	}
`;export const Step2Row=styled.div`
	display: flex;
	flex-direction: row;
`;export const Step3MainContent=styled.div`
	flex: 2;
	@media (max-width: 767px) {
		flex: 1;
		order: 2;
	}
`;export const Step3SecondaryContent=styled.div`
	flex: 1;
	order: 1;
	@media (max-width: 767px) {
		padding-bottom: 20px;
	}
`;export const MarginedLeft=styled.div`
	margin-left: 20px;
	& > p:first-child {
		margin-bottom: 0;
	}
	& > p:last-child {
		font-size: 18px;
	}
`;export const InlineSubSectionTitle=styled(SubSectionTitle)`
	display: inline-block;
	margin-bottom: 10px;
	margin-right: 10px;
	font-size: ${_ref11=>{let{fontSize}=_ref11;return fontSize||'inherit';}};
`;export const InfoIcon=styled.span`
	margin-left: 10px;
	font-size: 14px;
	font-family: 'Times New Roman', serif;
	background-color: #cdcdcd;
	padding: 4px 10px;
	color: white;
	font-style: italic;
	font-weight: bold;
	border-radius: 30px;
	cursor: help;
`;export const NumberInput=styled.input`
	text-align: center;
	border-radius: 5px;
	border: 1px solid #868889;
	width: 55px;
	font-size: 14px;
	padding: 6px 4px;
`;export const MLp=styled.p`
	margin-left: 22px;
`;export const TextInput=styled.input`
	text-align: right;
	border-radius: 5px;
	border: 1px solid #868889;
	width: 120px;
	font-size: 14px;
	padding: 8px 4px;
`;export const More=styled.a`
	text-decoration: underline;
	color: #d56244;
`;export const Contact=styled.div`
	p {
		margin: 0 0 0.5em 1em;
	}

	a {
		text-decoration: underline;
		color: #d56244;
		line-height: 2em;
	}
`;export const Radio=styled.label`
	input {
		cursor: pointer;
		display: inline-block;
		line-height: 1.25;
		position: relative;
		margin-right: 5px;
	}

	margin-right: 10px;
	color: #363636;
	display: block;
	font-weight: 500;
	font-size: 15px;
	padding-bottom: 15px;
	line-height: 1em;
`;export const TextFieldWrapper=styled(TextField)`
	.MuiOutlinedInput-root {
		background-color: white !important;
	}
`;export const FreeSpace=styled.div`
	width: 100%;
	height: 16px;
`;export const SelectFormControl=styled(FormControl)`
	&.MuiFormControl-root {
		width: 187px;
		margin-top: 12px;
	}
`;export const SelectFieldWrapper=styled(Select)`
	.MuiOutlinedInput-input {
		background-color: white !important;
	}
`;export const NoTopMarginSubSectionTitleBigger=styled(NoTopMarginSubSectionTitle)`
	font-size: 20px;
`;export const MarginedTextField=styled(TextField)`
	margin-left: 20px !important;

	.MuiOutlinedInput-root {
		background-color: white !important;
	}

	.Mui-error {
		font-size: 1rem;
	}
`;export const Errors=styled.p`
	color: red;
	margin-bottom: 0;
`;export const Times=styled.i`
	color: red;
	cursor: pointer;
`;export const Step2Styles=createGlobalStyle`
  .MuiInputBase-root, .MuiTypography-root {
    & > input {
        font-size: 16px! important;
        padding: 7px 10px !important;
    }
  }
  span.MuiTypography-root {
      font-size: 16px !important;

	  &.checkbox {
		position: relative;
		top: -2px;
		margin-bottom: 4px;
		display: inline-block;
	  }
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(1.1) !important;
  }
  .MuiInputBase-root {
      font-size: 1.5rem !important;
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 13px) !important;
  }
  .MuiFormControlLabel-root {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;

      & a {
          text-decoration: underline;
          color: #d56244;
      }
      & a:hover {
        text-decoration: none;
        color: #d56244;
    }
  }
  .MuiPopover-root {
	z-index: 9999 !important;
  }
`;
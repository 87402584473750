import gql from'graphql-tag';export const isListTokensEnabledQuery=gql`
	query IsListTokensEnabledQuery($subjectId: String!, $provider: TokenProvider, $type: TokenType) {
		listTokens(where: {subjectId: {equals: $subjectId}, provider: {equals: $provider}, type: {equals: $type}}) {
			id
			meta
		}
	}
`;export const sumUpSetUpTokensMutation=gql`
	mutation SumUpSetUpTokensMutation($subjectId: String!, $code: String!) {
		sumUpSetUpTokens(where: {subjectId: $subjectId, code: $code}) {
			success
			message
			access_token
		}
	}
`;export const setupIgAtMutation=gql`
	mutation SetupIgAtMutation($subjectId: String!, $code: String!) {
		setupIgAt(where: {subjectId: $subjectId, code: $code}) {
			success
			message
		}
	}
`;export const deleteManyTokensMutation=gql`
	mutation DeleteManyTokensMutation($subjectId: String!, $provider: TokenProvider!) {
		deleteManyTokens(where: {subjectId: {equals: $subjectId}, provider: {equals: $provider}}) {
			__typename
		}
	}
`;export const sumUpTokenQuery=gql`
	query SumUpTokenQuery($subjectId: String!, $forced: Int) {
		sumUpToken(where: {subjectId: $subjectId, forced: $forced}) {
			access_token
			meta
			subjectId
		}
	}
`;export const sumUpReceipt=gql`
	query SumUpReceipt($subjectId: String!, $receiptId: String!) {
		sumUpReceipt(where: {subjectId: $subjectId, receiptId: $receiptId}) {
			success
			message
			receipt
		}
	}
`;export const sumUpProfile=gql`
	query SumUpProfile($subjectId: String!) {
		sumUpProfile(where: {subjectId: $subjectId}) {
			success
			message
			profile
		}
	}
`;